import React, { useEffect, useState } from "react";
import { useAuth } from "../../../context/useAuth";
import moment from "moment";
import ShilohAlignmentUpdate from "./ShilohAlignmentUpdate";
import LogoutModal from "../../logoutModal/LogoutModal";
import DataTable from "react-data-table-component";

const ShilohAlignment = () => {
  const base_url = process.env.REACT_APP_BASE_URL;
  const today = new Date(Date.now());
  const programId = useAuth().programId;
  const token = useAuth().token;
  const [data, setData] = useState([]);
  const [isActionModalopen, setIsActionModalOpen] = useState(false);
  const [actionModalData, setActionModalData] = useState([]);
  const [isExpire, setIsExpire] = useState(false);

  const columns = [
    {
      name: "Id",
      selector: (row) => row.id,
      sortable: true,
      width: "100px",
    },
    {
      name: "Name",
      selector: (row) => row.client_name,
      sortable: true,
      width: "300px",
    },
    {
      name: "Intake Date",
      selector: (row) => convertTimeToDate(row.program_intake_date),
      sortable: true,
      width: "150px",
    },
    {
      name: "Program Completion Date",
      selector: (row) => convertTimeToDate(row.program_completion_date),
      sortable: true,
      width: "250px",
      cell: (row) => (
        <div
          style={{
            color:
              new Date(row.program_completion_date).getTime() <= today.getTime()
                ? "black"
                : "#BB2B3D",
            fontWeight:
              new Date(row.program_completion_date).getTime() <= today.getTime()
                ? ""
                : "bold",
          }}
        >
          {convertTimeToDate(row.program_completion_date)}
        </div>
      ),
    },
    {
      name: "Active Status",
      selector: (row) => (row.status == 1 ? "Active" : "Inactive"),
      sortable: true,
      width: "150px",
      cell: (row) => (
        <div
          style={{
            color: row.status == 1 ? "black" : "#BB2B3D",
            fontWeight: row.status == 1 ? "" : "bold",
          }}
        >
          {row.status == 1 ? "Active" : "Inactive"}
        </div>
      ),
    },
    {
      name: "Email",
      selector: (row) => (row.email == "" ? "No Email" : row.email),
      sortable: true,
      // width: "300px",
    },
    {
      name: "Phone",
      selector: (row) => (row.phone == undefined ? "No Phone" : row.phone),
      sortable: true,
      width: "200px",
    },
    {
      name: "Action",
      cell: (row) => (
        <button
          type="button"
          className="btn btn-primary"
          onClick={() => handleActionClick(row.id)}
        >
          Action
        </button>
      ),
      width: "150px",
    },
  ];

  const customStyles = {
    headCells: {
      style: {
        backgroundColor: "#F8F8F8",
        color: "black",
        fontSize: "15px",
        fontWeight: "bold",
        textAlign: "left",
      },
    },
    rows: {
      style: {
        fontSize: "15px", // Row text size
        color: "black", // Row text color
      },
    },
    table: {
      style: {
        width: "100%",
      },
    },
  };

  function convertTimeToDate(dateString) {
    const newDate = moment.utc(dateString).format("MMM Do YYYY");
    return newDate;
  }

  const handleActionClick = (e) => {
    const newData = data.filter((value) => {
      return value.id === e;
    });

    setActionModalData(newData);
    setIsActionModalOpen(true);
  };

  const closeActionModal = async () => {
    setIsActionModalOpen(false);
    await getShilohAlignment();
  };

  const getShilohAlignment = async () => {
    try {
      const payload = {
        programId: programId,
      };

      const response = await fetch(`${base_url}/client/shilohAlignment`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(payload),
      });
      if (!response.ok) {
        setIsExpire(true);
        return;
      }
      const data = await response.json();
      // console.log(`outer data : `, data);
      setData(data);
    } catch (error) {
      console.error("Error fetching Shiloh Alignment:", error);
    }
  };

  useEffect(() => {
    getShilohAlignment();
  }, []);

  return (
    <div className="">
      {isExpire && <LogoutModal isOpen={isExpire} setIsExpire={setIsExpire} />}
      <h3 className="pt-4">Shiloh Alignments</h3>
      <hr />

      <DataTable
        columns={columns}
        data={data}
        pagination
        customStyles={customStyles}
        highlightOnHover
      />

      {/* <div className="card mb-4">
        <div className="card-header text-center">New Client Details</div>
        <div className="container-fluid px-0">
          <table border="1" className="table table-striped table-hover">
            <thead>
              <tr className="text-center">
                <th scope="col">Id </th>
                <th scope="col">Name </th>
                <th scope="col">Intake Date </th>
                <th scope="col">Program Completion Date </th>
                <th scope="col">Active Status </th>
                <th scope="col">Email </th>
                <th scope="col">Phone </th>
              </tr>
            </thead>
            <tbody className="text-center">
              {data.map((row) => (
                <tr className=" " key={row.id}>
                  <td className="align-middle">{row.id}</td>
                  <td className="align-middle">{row.client_name}</td>
                  <td className="align-middle">
                    {convertTimeToDate(row.program_intake_date)}
                  </td>
                  <td
                    className={`align-middle ${
                      new Date(row.program_completion_date).getTime() <=
                      today.getTime()
                        ? "fw-bolder text-danger"
                        : ""
                    } `}
                  >
                    {convertTimeToDate(row.program_completion_date)}
                  </td>
                  <td
                    className={`align-middle ${
                      row.status == 0 ? "fw-bolder text-danger" : ""
                    } `}
                  >
                    {row.status == 1 ? "Active" : "Inactive"}
                  </td>
                  <td className="align-middle">
                    {row.email == "" ? "No Email" : row.email}
                  </td>
                  <td className="align-middle">
                    {row.phone == undefined ? "No Phone" : row.phone}
                  </td>
                  <td className="align-middle">
                    <button
                      className="btn btn-primary"
                      // style={{ height: "35px" }}
                      onClick={() => handleActionClick(row.id)}
                    >
                      Action
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          {isActionModalopen && (
            <ShilohAlignmentUpdate
              isOpen={isActionModalopen}
              onClose={closeActionModal}
              data={actionModalData}
            />
          )}
        </div>
      </div> */}

      {isActionModalopen && (
        <ShilohAlignmentUpdate
          isOpen={isActionModalopen}
          onClose={closeActionModal}
          data={actionModalData}
        />
      )}
    </div>
  );
};

export default ShilohAlignment;
