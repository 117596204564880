import Sidebar from "../components/Sidebar";
import DashboadScreen from "../components/optionScreens/dashboardScreen/DashboadScreen";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../context/useAuth";

const DashboardPage = () => {
  const navigate = useNavigate();
  const userName = useAuth().user;
  const handleOptionChange = (option) => {
    navigate(option.url);
  };

  return (
    <>
      <div className="d-flex flex-row w-100">
        <Sidebar handleOptionChange={handleOptionChange} />
        <div
          style={{ marginLeft: "80px", paddingRight: "30px", marginTop: "50px" }}
          className="w-100"
        >
          <DashboadScreen username={userName} />
        </div>
      </div>
    </>
  );
};

export default DashboardPage;
