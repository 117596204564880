import React from 'react'
import Sidebar from '../components/Sidebar'
import Program from '../components/optionScreens/programs/Program'
import { useNavigate } from "react-router-dom";

const ProgramPage = () => {

    const navigate = useNavigate();
    const handleOptionChange = (option) => {
      navigate(option.url);
    };
  return (
    <>
    <div className="d-flex flex-row w-100">
      <Sidebar handleOptionChange={handleOptionChange} />
      <div
        style={{ marginLeft: "80px", paddingRight: "30px", marginTop: "50px" }}
        className="w-100"
      >
        <Program />
      </div>
    </div>
  </>
  )
}

export default ProgramPage