import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { useAuth } from "../../../context/useAuth";
import moment from "moment";
import LogoutModal from "../../logoutModal/LogoutModal";

const ChangePassword = ({isOpen, onClose, data}) => {
    const token = useAuth().token;
    const role = useAuth().role;
    const adminId = useAuth().userId;
    const logout = useAuth().logout;
    const base_url = process.env.REACT_APP_BASE_URL;
    const [name, setName] = useState(data[0].UserName);
    const [password, setPassword] = useState("");
    const [userRole, setUserRole] = useState(data[0].Role);
    const [loading, setLoading] = useState(false);
    const [isExpire, setIsExpire] = useState(false);
  

    const handleChangePassword = () => {
        if (password === "") {
            toast.warn("Please enter new password!");
            return;
          }

          setLoading(true);
          const payload = {
            userId: data[0].UserID,
            password: password,
            updatedBy: adminId,
          };
      
          fetch(`${base_url}/user/update`, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify(payload),
          })
            .then((response) => {
              if (!response.ok) {
                setIsExpire(true);
                return;
              }
              toast.success("Password Updated Successful!");
              onClose();
            })
            .catch((error) => {
              console.error("Error Updating password:", error);
            })
            .finally(() => {
              setLoading(false);
              onClose();
            });
    }


  return (
    <Modal show={isOpen} onHide={onClose}>
      <Modal.Header closeButton>
        <Modal.Title>Change Password</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="">
          <input
            type="text"
            className="form-control mb-3"
            value={name}
            onChange={(e) => setName(e.target.value)}
            placeholder="Name"
            disabled
          />

          <input
            type="text"
            className="form-control mb-3"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            placeholder="New Password"
          />

          <select
            id="dropdown"
            value={userRole}
            onChange={(e) => {
              setUserRole(e.target.value);
              if (e.target.value == "Admin") {
                // setIsAdmin(true);
              } else {
                // setIsAdmin(false);
              }
            }}
            disabled
            className="form-select mb-3"
            aria-label="Default select example"
          >
            <option value="">Select Role</option>
            <option value="Super Admin">Super Admin</option>
            <option value="Admin">Admin</option>
            <option value="Viewer">Viewer</option>
          </select>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onClose}>
          Close
        </Button>
        <Button variant="primary" onClick={handleChangePassword}>
        Change Password
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export default ChangePassword