import { Calendar, momentLocalizer, dateFnsLocalizer } from "react-big-calendar";
import moment from "moment";
import "react-big-calendar/lib/css/react-big-calendar.css";
import { useEffect, useState } from "react";
import EventModal from "./EventModal";
import { useAuth } from "../../../context/useAuth";
import LogoutModal from "../../logoutModal/LogoutModal";

const CalendarComponent = () => {
  const localizer = momentLocalizer(moment);
  const token = useAuth().token;
  const programId = useAuth().programId;
  const base_url = process.env.REACT_APP_BASE_URL;

  const [data, setData] = useState([]);
  const [eventSelected, SetEventSelected] = useState(null);
  const [isOpenEvent, setIsOpenEvent] = useState(false);
  const [selectedFilter, setSeletedFilter] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  const [department, setDepartments] = useState([]);
  const [isExpire, setIsExpire] = useState(false);
  let allDept;
  const minTime = new Date();
  minTime.setHours(7, 0, 0);

  const maxTime = new Date();
  maxTime.setHours(20, 0, 0);
  const colors = [
    "#1B4F72",
    "#4A235A",
    "#006400",
    "#00008B",
    "#8B008B",
    "#3B0A45",
    "#1A0DAB",
    "#7B241C",
    "#556B2F",
    "#2F2F2F",
    "#003153",
    "#117864",
    "#4B0082",
    "#512E5F",
    "#1E8449",
    "#154360",
    "#014421",
    "#4D5656",
    "#7F8C8D",
    "#7B0038",
    "#145A32",
    "#2C3539",
    "#8B4513",
    "#2E8B57",
    "#6A5ACD",
    "#3B0A72",
    "#943126",
    "#1C2833",
    "#800080",
    "#0E6251",
  ];

  function convertTimeToDate(id, timeString) {
    const date = new Date(timeString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    const formattedDate = `${year}-${month}-${day}`;
    return formattedDate;
  }

  function convertToDateTime(dateString, timeString) {
    let newDate = dateString.split("T")[0];

    let newMergeDate = new Date(newDate + " " + timeString);

    return newMergeDate;
  }

  const getAppointments = async () => {
    try {
      const payload = {
        programId: programId,
      }

      const response = await fetch(`${base_url}/appointment/get`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(payload),
      });
      if (!response.ok) {
        setIsExpire(true);
        return;
      }
      let data = await response.json();

      for (var i = 0; i < data.length; i++) {
        var datetime1 = convertToDateTime(
          (await data)[i]["date"],
          (await data)[i]["start_time"]
        );
        var datetime2 = convertToDateTime(
          (await data)[i]["date"],
          (await data)[i]["end_time"]
        );
        data[i].StartTime1 = datetime1;
        data[i].EndTime1 = datetime2;
      }

      updateTimeString(data);
    } catch (error) {
      console.error("Error fetching appointments:", error);
    }
  };

  const updateTimeString = (result) => {
    const updatedData = result.map((item) => {
      let stTime = item.StartTime1;
      let endTime = item.EndTime1;

      return {
        ...item,
        start: stTime,
        end: endTime,
        title: item.client_name,
      };
    });

    const length = result.length;

    for (let i = 0; i < length; i++) {
      let depId = result[i].department_id;

      let dep = allDept.find((dept) => dept.id == depId);
      let color = dep.color;
      updatedData[i].color = color;
    }
    setData(updatedData);
    setFilteredData(updatedData);
  };

  const getDepartments = async () => {
    try {
      const response = await fetch(`${base_url}/department/get`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (!response.ok) {
        setIsExpire(true);
        return;
      }
      let data = await response.json();

      const length = data.length;

      for (let i = 0; i < length; i++) {
        data[i].color = colors[i];
      }
      allDept = data;
      setDepartments(data);
    } catch (error) {
      console.error("Error fetching appointments:", error);
    }
    return;
  };

  const handleEventClick = (event) => {
    event.ExcusedAbsence = event.excused_absence_comment;
    event.note = event.notes;
    SetEventSelected(event);
    setIsOpenEvent(true);
  };

  const handleChangeFilter = (event) => {
    const selectedValue = event.target.value;
    setSeletedFilter(selectedValue);

    if (selectedValue === "") {
      setFilteredData(data);
    } else {
      const filtered = data.filter(
        (item) => item.department_id === parseInt(selectedValue)
      );
      setFilteredData(filtered);
    }
  };

  const handleResetFilter = () => {
    setFilteredData(data);
    setSeletedFilter("");
  };

  const handleOpenEvent = () => {
    setIsOpenEvent(!isOpenEvent);
  };

  function updateAttendenceAPI(id, attand, remark) {

    if(attand === -1) {
      const updatedItems = filteredData.filter(item => item.id !== id);
      setFilteredData(updatedItems)
    }else{
      setFilteredData(prev => prev.map(att => att.id === id ? {...att, attendance: attand, excused_absence_comment: remark} : att))
    }
    handleOpenEvent();
  }

  const eventStyle = (event) => {
    return {
      style: {
        backgroundColor: event.color,
        color: "white",
      },
    };
  };

  useEffect(() => {
    getDepartments().then(() => {
      getAppointments();
    });
  }, []);

  return (
    <>
      <div>
        {isExpire && (
          <LogoutModal isOpen={isExpire} setIsExpire={setIsExpire} />
        )}
        <h3 className="pt-4">Calendar</h3>
        <hr />

        <div className="d-flex justify-content-center mb-3 ">
          <select
            className="form-select w-25 me-3 "
            value={selectedFilter}
            onChange={handleChangeFilter}
          >
            <option value="">All Departments</option>
            {department.map((dept, index) => {
              return (
                <>
                  <option key={index} value={dept.id}>
                    {dept.name}
                  </option>
                </>
              );
            })}
          </select>
          <button
            className="btn btn-primary"
            style={{ width: "150px" }}
            onClick={handleResetFilter}
          >
            Reset Filter
          </button>
        </div>
        <Calendar
          localizer={localizer}
          events={filteredData}
          selectable={true}
          startAccessor="start"
          endAccessor="end"
          onSelectEvent={handleEventClick}
          style={{ height: "620px" }}
          eventPropGetter={eventStyle}
          min={minTime}
          max={maxTime}
        />
        {eventSelected && (
          <EventModal
            handleOpenEvent={handleOpenEvent}
            isOpen={isOpenEvent}
            event={eventSelected}
            updateAttendenceAPI={updateAttendenceAPI}
          />
        )}
      </div>
    </>
  );
};

export default CalendarComponent;
