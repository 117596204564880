import { useAuth } from "../context/useAuth";
import { Navigate } from "react-router-dom";

// eslint-disable-next-line react/prop-types
export const UserRoute = ({ children }) => {
  const { user } = useAuth();
  if (user) {
    // user is authenticated

    return <Navigate to="/dashboard" />;
  }

  return children;
};
