import React, { useState } from "react";
import { CSVDownload } from "react-csv";
import moment from "moment";
import EventModal from "../calendar/EventModal";
import DataTable from "react-data-table-component";
// import EventModal from "../../modals/EventModal";

const FilterTable = ({ report, toggleVisibility }) => {
  const [download, setDownload] = useState(false);
  const [eventSelected, SetEventSelected] = useState(null);
  const [isOpenEvent, setIsOpenEvent] = useState(false);
  // console.log(`report : `, report);

  const columns = [
    {
      name: "Id",
      selector: (row) => row.AppointmentID,
      sortable: true,
      width: "70px",
    },
    {
      name: "Client",
      selector: (row) => row.ClientName,
      sortable: true,
      width: "200px",
    },
    {
      name: "Department",
      selector: (row) => row.DepartmentName,
      sortable: true,
      width: "180px",
    },
    {
      name: "Sub Department	",
      selector: (row) => row.SubdepartmentName,
      sortable: true,
      width: "200px",
    },
    {
      name: "Date",
      selector: (row) => convertTimeToDate(row.Date),
      sortable: true,
      width: "150px",
    },
    {
      name: "Start Time",
      selector: (row) => convertTimeToHHMM(row.StartTime),
      sortable: true,
      width: "130px",
    },
    {
      name: "End Time",
      selector: (row) => convertTimeToHHMM(row.EndTime),
      sortable: true,
      width: "120px",
    },
    {
      name: "Attendance",
      selector: (row) => (row.Attendance === 1) ?  ((row.ExcusedAbsence === null) ? ("Present") : ("Excused Absence")) : row.Attendance === 0 ? "Absent" : new Date(row.Date) <= new Date() ? "Unchecked" : "Future Booking",
      sortable: true,
      width: "150px",
    },
    {
      name: "Excused Absence",
      selector: (row) => row.ExcusedAbsence,
      sortable: true,
    },
    {
      name: "Updated By",
      selector: (row) => row.Updatedby,
      sortable: true,
      width: "150px",
    },
    {
      name: "Updated Time",
      selector: (row) => `${new Date(row.Lastupdate).toLocaleString("en-US", { timeZone: "America/New_York" })} ET`,
      sortable: true,
      width: "250px",
    },
  ]

  const customStyles = {
    headCells: {
      style: {
        backgroundColor: "#F8F8F8", 
        color: "black", 
        fontSize: "15px", 
        fontWeight: "bold", 
        textAlign: "left", 
      },
    },
    rows: {
      style: {
        fontSize: "15px", // Row text size
        color: "black", // Row text color
      },
    },
    table: {
      style: {
        width: "100%",
      },
    },
  };

  const handleOpenEvent = () => {
    setIsOpenEvent(!isOpenEvent);
  };

  function updateAttendenceAPI() {
    // handleClient(clientDropdownValue);
    // getUnCheckedAppointments();

  }

  var reportData = report[0];
  let data1 = [];

  function convertTimeToDate(dateString) {
    const newDate = moment(dateString, "YYYY-MM-DD").format("MMM Do YYYY");
    return newDate;
  }

  function handleEventClick(row) {
    row.name = row.DepartmentName;
    row.id = row.AppointmentID;
    row.client_name = row.ClientName;
    row.end_time = row.EndTime;
    row.excused_absence_comment = row.ExcusedAbsence;
    row.sub_department_name = row.SubdepartmentName;
    row.updated_at = row.Lastupdate;
    row.user_name = row.Updatedby;
    row.attendance = row.Attendance;
    row.date = row.Date;
    row.start_time = row.StartTime;
    SetEventSelected(row);
    setIsOpenEvent(true);
  }

  function convertTimeToHHMM(timeString) {
    const newTime = moment(timeString, "HH:mm:ss").format("hh:mm A");
    return newTime;
  }

  var dataForCSV = [
    [
      "AppointmentID",
      "Client",
      "Department",
      "Subdepartment",
      "StartTime",
      "EndTime",
      "Attendance",
      "Date",
      "ExcusedAbsence",
      "Updatedby",
      "Lastupdate",
    ],
  ];

  for (var i = 0; i < reportData.length; i++) {
    data1.push({
      AppointmentID: reportData[i]["id"],
      ClientName: reportData[i]["client_name"],
      DepartmentName: reportData[i]["department_name"],
      SubdepartmentName: reportData[i]["sub_department_name"],
      StartTime: reportData[i]["start_time"],
      EndTime: reportData[i]["end_time"],
      Attendance: reportData[i]["attendance"],
      Date: reportData[i]["date"],
      ExcusedAbsence: reportData[i]["excused_absence_comment"],
      Updatedby: reportData[i]["username"],
      Lastupdate: reportData[i]["updated_at"],
      note: reportData[i]["note"]
    });
    dataForCSV.push([
      reportData[i]["id"],
      reportData[i]["client_name"],
      reportData[i]["department_name"],
      reportData[i]["sub_department_name"],
      convertTimeToHHMM(reportData[i]["start_time"]),
      convertTimeToHHMM(reportData[i]["end_time"]),
      reportData[i]["attendance"] == 1 ? "Present" : "Absent",
      convertTimeToDate(reportData[i]["date"]),
      reportData[i]["excused_absence_comment"],
      reportData[i]["username"],
      reportData[i]["updated_at"],
    ]);
  }

  function downloadCSV() {
    setDownload(true);
    setTimeout(() => {
      setDownload(false);
    }, 500);
  }

  return (
    <div className="  ">
      <button type="button" className="btn btn-success" onClick={downloadCSV}>
        Download CSV
      </button>{" "}
      &nbsp;&nbsp;
      {download ? <CSVDownload data={dataForCSV} target="_blank" /> : <></>}
      <br />
      <br />
      <div>
        <h5 className="text-center">Reports ({reportData.length})</h5>
        <DataTable 
          customStyles={customStyles}
          highlightOnHover onRowClicked={ handleEventClick} columns={columns} data={data1} pagination />
      </div>
      {eventSelected && (
        <EventModal
          handleOpenEvent={handleOpenEvent}
          isOpen={isOpenEvent}
          event={eventSelected}
          updateAttendenceAPI={updateAttendenceAPI}
        />
      )}
    </div>
  );
};

export default FilterTable;
