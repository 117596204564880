import React from "react";
import Sidebar from "../components/Sidebar";
import { useNavigate } from "react-router-dom";
import User from "../components/optionScreens/user/User";

const UserPage = () => {
  const navigate = useNavigate();

  const handleOptionChange = (option) => {
    navigate(option.url);
  };

  return (
    <>
      <div className="d-flex flex-row w-100">
        <Sidebar handleOptionChange={handleOptionChange} />
        <div
          style={{ marginLeft: "80px", paddingRight: "30px", marginTop: "50px" }}
          className="w-100"
        >
          <User />
        </div>
      </div>
    </>
  );
};

export default UserPage;
