import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { useAuth } from "../../context/useAuth";

const LogoutModal = ({ isOpen, setIsExpire }) => {
  const { logout } = useAuth();
  const handleLogout = () => {
    setIsExpire(false);
    logout();
  };

  return (
    <>
      <Modal show={isOpen} backdrop="static" keyboard={false}>
        <Modal.Header>
          <Modal.Title>Session Expired</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Your session is expired. Click on Logout and try to login again.
        </Modal.Body>
        <Modal.Footer>
          <Button variant="btn btn-danger" onClick={handleLogout}>
            Logout
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default LogoutModal;
