import { useEffect, useState } from "react";
import { Piechart } from "../../PieChart";
import { GoOrganization } from "react-icons/go";
import { Link } from "react-router-dom";
import { useAuth } from "../../../context/useAuth";
import EventModal from "../calendar/EventModal";
import moment from "moment";
import LogoutModal from "../../logoutModal/LogoutModal";
import { Typeahead } from "react-bootstrap-typeahead";
import "react-bootstrap-typeahead/css/Typeahead.css";
import DataTable from "react-data-table-component";
import { toast } from "react-toastify";

const DashboadScreen = ({ username }) => {
  const base_url = process.env.REACT_APP_BASE_URL;
  const token = useAuth().token;
  const programId = useAuth().programId;
  const userId = useAuth().userId;
  const [data, setData] = useState([]);
  const [isExpire, setIsExpire] = useState(false);
  const [activeClients, setActiveClients] = useState([]);
  const [activeClient, setActiveClient] = useState([]);
  const [clientDropdownValue, setClientDropdownValue] = useState("");
  const [data1, setData1] = useState([]);
  const [subdepartment, setSubdepartment] = useState("");
  const [ClientComplianceRate, setClientComplianceRate] = useState(0);
  const [programProgressRate, setProgramProgressRate] = useState(0);
  const [totalAppointments, setTotalAppointments] = useState(0);
  const [totalPresent, setTotalPresent] = useState(0);
  const [totalAbsent, SetTotalAbsent] = useState(0);
  const [inActiveAppointments, setInActiveAppointments] = useState([]);
  const [eventSelected, SetEventSelected] = useState(null);
  const [isOpenEvent, setIsOpenEvent] = useState(false);
  const [numerator1, setNumerator1] = useState(0);
  const [denominator1, setDenominator1] = useState(0);
  const [numerator2, setNumerator2] = useState(0);
  const [denominator2, setDenominator2] = useState(0);
  const [uncheckedBooking, setUncheckedBooking] = useState(0);
  const [futureBooking, setFutureBooking] = useState(0);
  const [shilohAlignmentCount, setShailohAlignmentCount] = useState(0);
  const [departmentCount, setDepartmentCount] = useState(0);
  const [clientCount, setClientCount] = useState(0);
  const [activeClientCount, setActiveClientCount] = useState(0);
  const [inActiveClientCount, setInActiveClientCount] = useState(0);
  const [inActiveFilterAppointments, setInActiveFilterAppointments] = useState(
    []
  );
  const [Departments, setDepartments] = useState([]);
  const [Subdepartments, setSubdepartments] = useState([]);
  const [department, setDepartment] = useState("");

  const unCheckedColumns = [
    {
      name: "Id",
      selector: (row) => row.id,
      sortable: true,
      width: "5%",
    },
    {
      name: "Client",
      selector: (row) => row.client_name,
      sortable: true,
      width: "15%",
    },
    {
      name: "Department",
      selector: (row) => row.department_name,
      sortable: true,
      width: "10%",
    },
    {
      name: "Sub Department",
      selector: (row) => row.sub_department_name,
      sortable: true,
      width: "10%",
    },
    {
      name: "Date",
      selector: (row) => convertTimeToDate(row.date),
      sortable: true,
      width: "9%",
    },
    {
      name: "Time",
      selector: (row) => `${convertTimeToHHMM(row.start_time)} - ${convertTimeToHHMM(row.end_time)}`,
      sortable: true,
      width: "12%",
    },
    {
      name: "Attendance",
      selector: (row) =>
        `${row.attendance === 1
          ? ((row.excused_absence_comment === null) ? ("Present") : ("Excused Absence"))
          : row.attendance === 0
            ? "Absent"
            : new Date(row.date) <= new Date()
              ? "Unchecked"
              : "Future Booking"
        }`,
      sortable: true,
      width: "9%",
    },
    {
      name: "Updated By",
      selector: (row) => row.user_name,
      sortable: true,
      width: "17%",
    },
    {
      name: "Action",
      cell: (row) => (
        <div className="d-flex gap-2">
        <button
          className="btn btn-primary"
          onClick={() => handlePresent(row.id)}
        >
          Present
        </button>
        <button
          className="btn btn-danger"
          onClick={() => handleAbsent(row.id)}
        >
          Absent
        </button>
        </div>
        
        
      ),
      width: "13%",
    },
  ];

  const appointmentColumns = [
    {
      name: "Id",
      selector: (row) => row.id,
      sortable: true,
      width: "5%",
    },
    {
      name: "Client",
      selector: (row) => row.client_name,
      sortable: true,
      width: "20%",
    },
    {
      name: "Department",
      selector: (row) => row.department_name,
      sortable: true,
      width: "10%",
    },
    {
      name: "Sub Department",
      selector: (row) => row.sub_department_name,
      sortable: true,
      width: "10%",
    },
    {
      name: "Date",
      selector: (row) => convertTimeToDate(row.date),
      sortable: true,
      width: "10%",
    },
    // {
    //   name: "Start Time",
    //   selector: (row) => convertTimeToHHMM(row.start_time),
    //   sortable: true,
    //   width: "10%",
    // },
    // {
    //   name: "End Time",
    //   selector: (row) => convertTimeToHHMM(row.end_time),
    //   sortable: true,
    //   width: "10%",
    // },
    {
      name: "Time",
      selector: (row) => `${convertTimeToHHMM(row.start_time)} - ${convertTimeToHHMM(row.end_time)}`,
      sortable: true,
      width: "15%",
    },
    {
      name: "Attendance",
      selector: (row) =>
        `${row.attendance
          ? ((row.excused_absence_comment === null) ? ("Present") : ("Excused Absence"))
          : row.attendance === 0
            ? "Absent"
            : new Date(row.date) <= new Date()
              ? "Unchecked"
              : "Future Booking"
        }`,
      sortable: true,
      width: "10%",
    },
    {
      name: "Updated By",
      selector: (row) => row.user_name,
      sortable: true,
      width: "15%",
    },
  ];

  const customStyles = {
    headCells: {
      style: {
        backgroundColor: "#F8F8F8",
        color: "black",
        fontSize: "15px",
        fontWeight: "bold",
        textAlign: "left",
      },
    },
    rows: {
      style: {
        fontSize: "15px", // Row text size
        color: "black", // Row text color
      },
    },
    table: {
      style: {
        width: "100%",
      },
    },
  };

  const handleOpenEvent = () => {
    setIsOpenEvent(!isOpenEvent);
  };

  function updateAttendenceAPI() {
    handleClient(clientDropdownValue);
    getUnCheckedAppointments();
    handleOpenEvent();
  }

  function handleEventClick(row) {
    row.name = row.department_name;
    row.ExcusedAbsence = row.excused_absence_comment;
    row.note = row.notes;
    SetEventSelected(row);
    setIsOpenEvent(true);
  }

  function handleClient(value) {
    if (value === "") {
      setData1([]);
      setClientComplianceRate(0);
      setProgramProgressRate(0);
      setNumerator1(0);
      setDenominator1(0);
      setNumerator2(0);
      setDenominator2(0);
      setTotalAppointments(0);
      setTotalPresent(0);
      SetTotalAbsent(0);
      return;
    }

    const apiUrl = `${base_url}/appointment/getByClient`;
    const clientData = activeClients.find(
      (client) => client.split(":")[1] == value[0]
    );

    let clientId = clientData.split(":")[0];

    const filteredData = inActiveAppointments.filter(
      (appointment) => appointment.client_id == clientId
    );

    setInActiveFilterAppointments(filteredData);

    var payload = {
      clientID: clientData.split(":")[0],
      programId: programId,
    };

    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(payload),
    };

    fetch(apiUrl, requestOptions)
      .then(async (response) => {
        if (!response.ok) {
          setIsExpire(true);
          throw new Error("Network response was not ok");
        }
        var result = await response.json();
        // console.log(`result: `, result)

        var temp = [];
        var totalNull = 0;
        var PresentSum = 0;
        let totalUnchecked = 0;
        let futureUnchecked = 0;

        for (var i = 0; i < result.length; i++) {
          if (result[i]["attendance"] === null) {
            totalNull = totalNull + 1;
          }

          if (
            new Date(result[i]["date"]) <= new Date() &&
            result[i]["attendance"] == null
          ) {
            totalUnchecked = totalUnchecked + 1;
          }

          if (new Date(result[i]["date"]) > new Date()) {
            futureUnchecked = futureUnchecked + 1;
          }

          PresentSum = PresentSum + result[i]["attendance"];
          temp.push({
            id: result[i]["id"],
            client_name: result[i]["client_name"],
            department_name: result[i]["department_name"],
            sub_department_name: result[i]["sub_department_name"],
            start_time: result[i]["start_time"],
            end_time: result[i]["end_time"],
            attendance: result[i]["attendance"],
            excused_absence_comment: result[i]["excused_absence_comment"],
            date: result[i]["date"],
            user_name: result[i]["username"],
            updated_at: result[i]["updated_at"],
            notes: result[i]['notes']
          });
        }

        setData1(temp);
        var length = temp.length;
        setTotalAppointments(length);
        setTotalPresent(PresentSum);
        SetTotalAbsent(length - totalNull - PresentSum);
        setUncheckedBooking(totalUnchecked);
        setFutureBooking(futureUnchecked);
        var Total = parseInt(PresentSum) / parseInt(length - totalNull);
        var total2 = parseInt(length - totalNull) / parseInt(length);
        if (length == 0) {
          setClientComplianceRate(0);
          setProgramProgressRate(0);
          setNumerator1(0);
          setDenominator1(0);
          setNumerator2(0);
          setDenominator2(0);
        } else {
          if (parseInt(length - totalNull) == 0) {
            setClientComplianceRate(0);
          } else {
            setClientComplianceRate(Total * 100);
            setProgramProgressRate(total2 * 100);
            setNumerator1(parseInt(PresentSum));
            setDenominator1(parseInt(length - totalNull));
            setNumerator2(parseInt(length - totalNull));
            setDenominator2(parseInt(length));
          }
        }
      })
      .then((data) => { })

      .catch((error) => {
        console.error("Error sending data:", error);
      })
      .finally(() => { });
  }

  function convertTimeToDate(dateString) {
    const newDate = moment.utc(dateString).format("MMM Do YYYY");
    return newDate;
  }

  function convertTimeToHHMM(timeString) {
    const newTime = moment(timeString, "HH:mm:ss").format("hh:mm A");
    return newTime;
  }

  const getActiveClients = async () => {
    try {
      const payload = {
        programId: programId,
      };

      const response = await fetch(`${base_url}/client/get/active`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(payload),
      });
      if (!response.ok) {
        setIsExpire(true);
        return;
      }
      const data = await response.json();
      var temp = [];
      await new Promise((resolve, reject) => {
        for (var i = 0; i < data.length; i++) {
          temp.push(`${data[i]["id"]}:${data[i]["client_name"]}`);
        }
        resolve(temp);
      });
      setActiveClients(temp);
    } catch (error) {
      console.error("Error fetching clients:", error);
    }
  };

  const getUnCheckedAppointments = async () => {
    const payload = {
      programId: programId,
    };

    try {
      const response = await fetch(
        `${base_url}/appointment/uncheckedAppointments`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify(payload),
        }
      );
      if (!response.ok) {
        setIsExpire(true);
        return;
      }
      const data = await response.json();

      const currentDate = new Date();

      let tempData = [];

      for (let i = 0; i < data.length; i++) {
        if (new Date(data[i].date) < currentDate) {
          tempData.push(data[i]);
        }
      }
      setData(tempData)
      setInActiveFilterAppointments(tempData);
      setInActiveAppointments(tempData);
    } catch (error) {
      console.error("Error fetching appointments:", error);
    }
  };

  const getCount = async () => {
    try {
      const payload = {
        programId: programId,
      };


      const response = await fetch(`${base_url}/client/getCounts`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(payload),
      });
      if (!response.ok) {
        setIsExpire(true);
        return;
      }

      const data = await response.json();
      setDepartmentCount(data[0].count);
      setClientCount(data[2].count);
      setActiveClientCount(data[3].count);
      setInActiveClientCount(data[4].count);
    } catch (error) {
      console.error("Error fetching Counts:", error);
    }
  };
  const getShilohAlignment = async () => {
    try {
      const payload = {
        programId: programId,
      };

      const response = await fetch(`${base_url}/client/shilohAlignment`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(payload),
      });
      if (!response.ok) {
        setIsExpire(true);
        return;
      }
      const data = await response.json();

      setShailohAlignmentCount(data.length);
    } catch (error) {
      console.error("Error fetching Shiloh Alignment:", error);
    }
  };

  const handleTypeaheadChange = (selected) => {
    setActiveClient(selected);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setClientDropdownValue(activeClient);
    handleClient(activeClient);
  };



  const getDepartments = async () => {
    try {
      const response = await fetch(`${base_url}/department/get`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (!response.ok) {
        setIsExpire(true);
        return;
      }
      const data = await response.json();
      var temp = [];
      await new Promise((resolve, reject) => {
        for (var i = 0; i < data.length; i++) {
          temp.push(`${data[i]["id"]}:${data[i]["name"]}`);
        }
        resolve(temp);
      });
      setDepartments(temp);
    } catch (error) {
      console.error("Error fetching departments:", error);
    }
  };

  const handlePresent = (e) => {

    const apiUrl = `${base_url}/appointment/attendance`;
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        AppointmentID: e,
        attendance: 1,
        remark: "",
        updatedBy: userId,
      }),
    };

    fetch(apiUrl, requestOptions)
      .then((response) => {
        if (!response.ok) {
          setIsExpire(true);
          return;
        }
        return response.json();
      })
      .then((data) => {
        setInActiveFilterAppointments(prev => prev.map(att => att.id === e ? {...att, attendance: 1} : att))
        toast.success(`Attendance updated Successfully!`);
      })
      .catch((error) => {
        toast.warn(`Attendance is not updated`);
      });
  };

  const handleAbsent = (e) => {
    const apiUrl = `${base_url}/appointment/attendance`;
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        AppointmentID: e,
        attendance: 0,
        remark: null,
        updatedBy: userId,
      }),
    };

    fetch(apiUrl, requestOptions)
      .then((response) => {
        if (!response.ok) {
          setIsExpire(true);
          return;
        }
        return response.json();
      })
      .then((data) => {
        setInActiveFilterAppointments(prev => prev.map(att => att.id === e ? {...att, attendance: 0} : att))
        toast.success(`Attendance updated Successfully!`);
      })
      .catch((error) => {
        toast.warn(`Attendance is not updated`);
      });
  };

  const getSubdepartments = async (val) => {
    try {
      const response = await fetch(`${base_url}/subdepartment/deptId/${val}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (!response.ok) {
        setIsExpire(true);
        return;
      }
      const data = await response.json();
      var temp = [];
      await new Promise((resolve, reject) => {
        for (var i = 0; i < data.length; i++) {
          temp.push(
            `${data[i]["id"]}:${data[i]["name"]}:${data[i]["slot_duration"]}`
          );
        }
        resolve(temp);
      });
      setSubdepartments(temp);
    } catch (error) {
      console.error("Error fetching subdepartments:", error);
    }
  };


  useEffect(() => {
    getActiveClients();
    getUnCheckedAppointments();
    getShilohAlignment();
    getCount();
    getDepartments();
  }, []);

  

  return (
    <div className="">
      {isExpire && <LogoutModal isOpen={isExpire} setIsExpire={setIsExpire} />}
      <h3 className="pt-4 ">Welcome {username},</h3>

      <hr />
      <div className="row">
        <div className="col-lg-6 col-xl-3 mb-4" style={{ width: "20%" }}>
          <div className="card bg-primary  text-white h-100">
            <div className="card-body">
              <div className="d-flex justify-content-between align-items-center">
                <div className="me-3">
                  <div className="text-white-75 fw-bolder small">
                    Department
                  </div>
                  <div className="fs-4 fw-bold">{departmentCount}</div>
                </div>
                <div className="text-white bg-[#6366F1]  w-12 h-12 flex justify-center items-center rounded-md">
                  <GoOrganization size={30} />
                </div>
              </div>
            </div>

            <div className="card-footer d-flex align-items-center justify-content-between small">
              <Link to={"/department"} className="text-white stretched-link">
                View All
              </Link>
            </div>
          </div>
        </div>

        <div className="col-lg-6 col-xl-3 mb-4" style={{ width: "20%" }}>
          <div className="card bg-warning  text-white h-100">
            <div className="card-body">
              <div className="d-flex justify-content-between align-items-center">
                <div className="me-3">
                  <div className="text-white-75 fw-bolder small">
                    Shiloh Alignments
                  </div>
                  <div className="fs-4 fw-bold">{shilohAlignmentCount}</div>
                </div>
                <div className="text-white bg-[#6366F1]  w-12 h-12 flex justify-center items-center rounded-md">
                  <GoOrganization size={30} />
                </div>
              </div>
            </div>

            <div className="card-footer d-flex align-items-center justify-content-between small">
              <Link
                to={"/shilohAlignment"}
                className="text-white stretched-link"
              >
                View All
              </Link>
            </div>
          </div>
        </div>

        <div className="col-lg-6 col-xl-3 mb-4" style={{ width: "20%" }}>
          <div className="card bg-secondary text-white h-100">
            <div className="card-body">
              <div className="d-flex justify-content-between align-items-center">
                <div className="me-3">
                  <div className="text-white-75 fw-bolder small">Clients</div>
                  <div className="fs-4 fw-bold">{clientCount}</div>
                </div>
                <div className="text-white bg-[#6366F1]  w-12 h-12 flex justify-center items-center rounded-md">
                  <GoOrganization size={30} />
                </div>
              </div>
            </div>

            <div className="card-footer d-flex align-items-center justify-content-between small">
              <Link to={"/client"} className="text-white stretched-link">
                View All
              </Link>
            </div>
          </div>
        </div>

        <div className="col-lg-6 col-xl-3 mb-4" style={{ width: "20%" }}>
          <div className="card bg-success  text-white h-100">
            <div className="card-body">
              <div className="d-flex justify-content-between align-items-center">
                <div className="me-3">
                  <div className="text-white-75 fw-bolder small">
                    Active Clients
                  </div>
                  <div className="fs-4 fw-bold">{activeClientCount}</div>
                </div>
                <div className="text-white bg-[#6366F1]  w-12 h-12 flex justify-center items-center rounded-md">
                  <GoOrganization size={30} />
                </div>
              </div>
            </div>

            <div className="card-footer d-flex align-items-center justify-content-between small">
              <Link
                to={"/client"}
                state={{ Clientvalue: "Active" }}
                className="text-white stretched-link"
              >
                View All
              </Link>
            </div>
          </div>
        </div>

        <div className="col-lg-6 col-xl-3 mb-4" style={{ width: "20%" }}>
          <div className="card bg-danger text-white h-100">
            <div className="card-body">
              <div className="d-flex justify-content-between align-items-center">
                <div className="me-3">
                  <div className="text-white-75 fw-bolder small">
                    InActive Clients
                  </div>
                  <div className="fs-4 fw-bold">{inActiveClientCount}</div>
                </div>
                <div className="text-white bg-[#6366F1]  w-12 h-12 flex justify-center items-center rounded-md">
                  <GoOrganization size={30} />
                </div>
              </div>
            </div>

            <div className="card-footer d-flex align-items-center justify-content-between small">
              <Link
                to="/client"
                state={{ Clientvalue: "Inactive" }}
                className="text-white stretched-link"
              >
                View All
              </Link>
            </div>
          </div>
        </div>
      </div>

      <hr />
      <h5>Client Progress</h5>

      {/* break */}
      <form onSubmit={handleSubmit} className="">
        <div className=" row">
          <div className="row mt-2 w-75 mb-3">
            <Typeahead
              className="w-50"
              id="client"
              onChange={handleTypeaheadChange}
              options={activeClients.map((elem) => elem.split(":")[1])}
              selected={activeClient}
              placeholder="Select a Client"
            />
            <button
              type="Submit"
              className="btn btn-primary "
              style={{ width: "100px" }}
            >
              Submit
            </button>
          </div>
        </div>
      </form>
      {/* break */}
      <div className="mt-4 ">
        <div className="col-xl-6 mb-4 w-100 d-flex justify-content-between">
          <div className="card card-header-actions" style={{ width: "30%" }}>
            <div className="card-header fw-bold">Compliance Rate</div>
            <div className="card-body">
              <div style={{ height: "300px" }} className="chart-area px-3">
                <Piechart
                  Rate={ClientComplianceRate}
                  Present={"Total Compliance"}
                  Absent={"Absence"}
                  Numerator={numerator1}
                  Denominator={denominator1}
                  isCompliance={true}
                />
              </div>
            </div>
          </div>
          <div className="card card-header-actions " style={{ width: "30%" }}>
            <div className="card-header fw-bold">Appointment Stats</div>
            <div className="card-body">
              <div style={{ height: "310px" }} className="chart-area">
                <div className="card">
                  <ul className="list-group list-group-flush">
                    <li className="list-group-item d-flex justify-content-between">
                      <span>Total Appointments Booked</span>
                      <span className="text-end">{totalAppointments}</span>
                    </li>
                    <li className="list-group-item d-flex justify-content-between">
                      <span>Total Present</span>
                      <span className="text-end">{totalPresent}</span>
                    </li>
                    <li className="list-group-item d-flex justify-content-between">
                      <span>Total Absent</span>
                      <span className="text-end">{totalAbsent}</span>
                    </li>
                    <li className="list-group-item d-flex justify-content-between">
                      <span>Future Appointments</span>
                      <span className="text-end">{futureBooking}</span>
                    </li>
                    <li className="list-group-item d-flex justify-content-between">
                      <span>Total Unchecked</span>
                      <span className="text-end">{uncheckedBooking}</span>
                    </li>
                  </ul>
                </div>
                <div className="fs-1 fw-bolder text-center d-flex mt-3 align-items-center justify-content-center">
                  <div className=" lh-1">
                    <p className="">
                      {totalPresent} / {totalAbsent + totalPresent}
                    </p>
                    <p className="" style={{ fontSize: "15px" }}>
                      Current Appointment Success Rate
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="card card-header-actions  " style={{ width: "30%" }}>
            <div className="card-header fw-bold">Program Progress Rate</div>
            <div className="card-body">
              <div style={{ height: "300px" }} className="chart-area px-2">
                <Piechart
                  Rate={programProgressRate}
                  Present={"Completed Appointments"}
                  Absent={"Total Appointments"}
                  Numerator={numerator2}
                  Denominator={denominator2}
                  isCompliance={false}
                />
              </div>
            </div>
          </div>
        </div>

        <hr className="mt-5" />
        <div>
          <div className="d-flex mb-3">
            <div className="w-50">
              <h5 className="text-center">
                Unchecked Appointment Details ({inActiveFilterAppointments.length})
              </h5>
            </div>
            <div className="mb-3 row w-25">

              <div className="col-sm-10">
                <select
                  className="form-select w-100"
                  id="department"
                  aria-label="Default select example"
                  defaultValue=""
                  onChange={async (e) => {
                    if ((e.target.value) !== "") {
                      const filtered = data.filter(
                        (item) => item.department_id === parseInt(e.target.value)
                      );
                      setInActiveFilterAppointments(filtered);
                      await getSubdepartments(e.target.value);
                    } else {
                      setInActiveFilterAppointments(data);
                      setSubdepartments([]);
                    }
                    setDepartment(e.target.value);
                  }}
                >
                  <option value="">Select a Department</option>
                  {Departments.map((option, index) => (
                    <option key={index} value={option.split(":")[0]}>
                      {option.split(":")[1]}
                    </option>
                  ))}
                </select>
              </div>
            </div>

            <div className="mb-3 row w-25">
              <div className="col-sm-10">
                <select
                  className="form-select w-100"
                  id="subdepartment"
                  aria-label="Default select example"
                  onChange={async (e) => {
                    if (isNaN(e.target.value) === false) {
                      const filtered = data.filter(
                        (item) => item.sub_department_id === parseInt(e.target.value)
                      );
                      setInActiveFilterAppointments(filtered);
                    } else {
                      setInActiveFilterAppointments(data);
                    }
                    setSubdepartment(e.target.value);
                  }}
                >
                  <option selected>Select a Sub Department</option>
                  {Subdepartments.map((option, index) => (
                    <option key={index} value={option.split(":")[0]}>
                      {option.split(":")[1]}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </div>

          <DataTable
            customStyles={customStyles}
            highlightOnHover
            onRowClicked={handleEventClick}
            // onClick={() => handleEventClick(row)}
            columns={unCheckedColumns}
            data={inActiveFilterAppointments}
            pagination
          />
        </div>
        <hr />
        <div>
          <h5 className="text-center">Client Appointments ({data1.length})</h5>
          <DataTable
            customStyles={customStyles}
            highlightOnHover
            onRowClicked={handleEventClick}
            columns={appointmentColumns}
            data={data1}
            pagination
          />
        </div>
      </div>
      {eventSelected && (
        <EventModal
          handleOpenEvent={handleOpenEvent}
          isOpen={isOpenEvent}
          event={eventSelected}
          updateAttendenceAPI={updateAttendenceAPI}
        />
      )}
    </div>
  );
};

export default DashboadScreen;
