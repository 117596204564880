import React, { useEffect, useState } from "react";
import { useAuth } from "../context/useAuth";
import { IoLogOut } from "react-icons/io5";

const Navbar = () => {
  const base_url = process.env.REACT_APP_BASE_URL;
  const [isExpire, setIsExpire] = useState(false);
  const { logout, role, token, programId, changeProgram } = useAuth();
  const handleLogout = () => {
    logout();
  };
  

  const [programs, setPrograms] = useState([]);

  const getPrograms = async () => {
    try {
      const response = await fetch(`${base_url}/program/get`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (!response.ok) {
        setIsExpire(true);
        return;
      }
      const data = await response.json();
      // console.log("programs", data);
      setPrograms(data);
    } catch (error) {
      console.error("Error fetching clients:", error);
    }
  };

  const handleChangeFilter = (e) => {
    // setProgram(e.target.value);
    // console.log(e.target.value);
    changeProgram(e.target.value);
    window.location.reload();
  }

  useEffect(() => {
    if (role !== "Viewer") {
      getPrograms();
    }
  }, [role]);
  
  return (
    <div className="">
      <nav
        className="navbar navbar-expand-lg position-fixed top-0 w-100  z-1 navbar-light text-bg-dark "
        style={{ height: "65px" }}
      >
        <div className="container-fluid">
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarTogglerDemo01"
            aria-controls="navbarTogglerDemo01"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarTogglerDemo01">
            <div
              className="d-flex justify-content-between align-items-center w-100"
              style={{ paddingLeft: "50px" }}
            >
              <a href="/dashboard">
                <img
                  width="150"
                  height="80"
                  src="https://hypecounselingservices.org/wp-content/uploads/2020/06/HYPE_CARF-Logo-REAL-BIG-GOLD.png"
                  alt="logo"
                  style={{ marginLeft: "15px" }}
                />
              </a>

              <select
                className="form-select w-25 me-3 "
                value={programId}
                onChange={handleChangeFilter}
              >
                {/* <option value="">Select Program</option> */}
                {programs.map((program, index) => {
                  return (
                    <>
                      <option key={index} value={program.id}>
                        {program.name}
                      </option>
                    </>
                  );
                })}
              </select>
              <div className="d-flex justify-content-between align-items-center gap-3">
                {role == "Super Admin" && (
                  <div className="bg-white text-dark px-3 py-1 rounded text-sm fw-semibold">
                    Super Admin
                  </div>
                )}

                <button
                  type="button"
                  className="btn btn-danger btn-md d-flex justify-content-center align-items-center "
                  onClick={handleLogout}
                >
                  <IoLogOut />
                  <div className="ms-2">Logout</div>
                </button>
              </div>
            </div>
          </div>
        </div>
      </nav>
    </div>
  );
};

export default Navbar;
