import { useState, useEffect } from "react";
import { toast } from "react-toastify";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { useAuth } from "../../../context/useAuth";
import LogoutModal from "../../logoutModal/LogoutModal";

const AddProgram = ({ isOpen, onClose }) => {
  const token = useAuth().token;
  const userId = useAuth().userId;
  const base_url = process.env.REACT_APP_BASE_URL;
  const [name, setName] = useState("");
  const [isExpire, setIsExpire] = useState(false);

  useEffect(() => {
    if (!isOpen) {
      setName("");
      
    }
  }, [isOpen]);

  const handleAddClick = () => {
    if (name == "") {
      toast.warn("Please fill required fields");
      return;
    }
    const payload = {
      name: name,
      updatedBy: userId,
    };

    // console.log(payload);

    fetch(`${base_url}/program/add`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(payload),
    })
      .then((response) => {
        if (!response.ok) {
          setIsExpire(true);
          return;
        }
        toast.success("Program added Successful!");
        setName("");
        onClose();
      })
      .catch((error) => {
        console.error("Error adding Program:", error);
      })
      .finally(() => {
        onClose();
      });
  };

  return (
    <>
      <Modal show={isOpen} onHide={onClose} backdrop="static" keyboard={false}>
        <Modal.Header closeButton>
          <Modal.Title>Add A Program</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="">
            <div className="d-flex align-items-center mb-3">
              <label htmlFor="name" className="w-25">
                Name
              </label>
              <input
                type="text"
                id="name"
                className="form-control "
                value={name}
                onChange={(e) => setName(e.target.value)}
                placeholder="Name"
              />
            </div>

          </div>
          {isExpire && (
            <LogoutModal isOpen={isExpire} setIsExpire={setIsExpire} />
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={onClose}>
            Close
          </Button>
          <Button variant="primary" onClick={handleAddClick}>
            Add Program
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default AddProgram;
