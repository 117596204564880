import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import React, { useState, useEffect } from "react";
import { Typeahead } from "react-bootstrap-typeahead";
import "react-bootstrap-typeahead/css/Typeahead.css";
import { toast } from "react-toastify";
import { useAuth } from "../../../context/useAuth";
import LogoutModal from "../../logoutModal/LogoutModal";

const AddASubDepartment = ({ isOpen, onClose }) => {
  const base_url = process.env.REACT_APP_BASE_URL;
  const token = useAuth().token;
  const userId = useAuth().userId;
  const [department, setDepartment] = useState([]);
  const [subdepartment, setSubdepartment] = useState([]);
  const [slotDuration, setSlotDuration] = useState("");
  const [Departments, setDepartments] = useState([]);
  const logout = useAuth().logout;
  const [isExpire, setIsExpire] = useState(false);

  const getDepartments = async () => {
    try {
      const response = await fetch(`${base_url}/department/get`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (!response.ok) {
        setIsExpire(true);
        return;
      }
      const data = await response.json();
      var temp = [];
      await new Promise((resolve, reject) => {
        for (var i = 0; i < data.length; i++) {
          temp.push(`${data[i]["id"]}:${data[i]["name"]}`);
        }
        resolve(temp);
      });
      // console.log(`Departments Data on subdepartment component: `, temp);
      setDepartments(temp);
    } catch (error) {
      console.error("Error fetching departments:", error);
    }
  };

  const handleSubmit = async () => {
    var departmentID = await new Promise((resolve, reject) => {
      Departments.forEach((element) => {
        if (element.includes(department[0])) {
          resolve(element.split(":")[0]);
        }
      });
    });
    var payload = {
      departmentID: departmentID,
      name: subdepartment,
      slotDuration: slotDuration,
      updatedBy: userId,
    };
    const apiUrl = `${base_url}/subdepartment/add`;
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(payload),
    };

    fetch(apiUrl, requestOptions)
      .then((response) => {
        if (!response.ok) {
          setIsExpire(true);
          return;
        }
        toast.success("Subdepartment added Successful!");
        return response.json();
      })
      .then((data) => {
        // console.log("Data sent successfully:", data);
        setSubdepartment([]);
        setSlotDuration("");
      })

      .catch((error) => {
        console.error("Error sending data:", error);
        toast.warn("There was an error");
      })
      .finally(() => {
        // toast.success("Data added!");
      });
  };

  useEffect(() => {
    getDepartments();
    // console.log(`departments: `, department);
  }, [isOpen]);

  return (
    <>
      <Modal show={isOpen} onHide={onClose}>
        <Modal.Header closeButton>
          <Modal.Title>Add A Sub Department</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {/* <form onSubmit={handleSubmit} id="addSubDepartment"> */}
          {isExpire && (
            <LogoutModal isOpen={isExpire} setIsExpire={setIsExpire} />
          )}
          <div className="mb-2">
            <label htmlFor="department" className="">
              Department:
            </label>
            <Typeahead
              id="department"
              onChange={(selected) => setDepartment(selected)}
              className="w-100"
              options={Departments.map((elem) => elem.split(":")[1])}
            />
          </div>

          <div className="mb-2">
            <label className="" htmlFor="subdepartment">
              Sub Department:
            </label>
            <input
              type="text"
              className="form-control w-100 mt-1"
              id="subdepartment"
              value={subdepartment}
              onChange={(e) => setSubdepartment(e.target.value)}
            />
          </div>

          <div className="mb-2">
            <label className="" htmlFor="slotDuration">
              Slot Duration (in minutes):
            </label>
            <input
              type="number"
              className="form-control w-100 mt-1"
              id="slotDuration"
              value={slotDuration}
              onChange={(e) => setSlotDuration(e.target.value)}
            />
          </div>
          {/* </form> */}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={onClose}>
            Close
          </Button>
          <Button
            onClick={() => handleSubmit().then(onClose)}
            variant="primary"
          >
            Add Sub Department
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default AddASubDepartment;
