/* eslint-disable react/prop-types */
// import { Pie } from "react-chartjs-2";
import { Doughnut } from "react-chartjs-2";
import { Chart as ChartJS, Tooltip, Legend, ArcElement } from "chart.js";

ChartJS.register(Tooltip, Legend, ArcElement);

export const Piechart = ({
  Rate,
  Present,
  Absent,
  Numerator,
  Denominator,
  isCompliance,
}) => {
  var options = {
    maintainAspectRatio: false,
    responsive: true,
  };
  const data = {
    labels: [Present, Absent],
    datasets: [
      {
        label: "Compliance",
        data: [Rate, 100 - Rate],
        fraction: [Numerator, Denominator],
        backgroundColor: ["rgb(60,179,113)", "rgb(220,20,60)"],
        borderColor: ["rgb(60,179,113)", "rgb(220,20,60)"],
        cutout: "80%",
        hoverOffset: 4,
      },
    ],
  };

  const doughnutLabel = {
    id: "doughnutLabel",
    afterDatasetsDraw(chart, args, plugins) {
      const { ctx, data } = chart;

      let percentage = 0;
      let nume = data.datasets[0].fraction[0];
      let deno = data.datasets[0].fraction[1];

      if (Number.isInteger(data.datasets[0].data[0])) {
        percentage = data.datasets[0].data[0];
      } else {
        percentage = (Math.round(data.datasets[0].data[0] * 100) / 100).toFixed(
          2
        );
      }

      const centerX = chart.getDatasetMeta(0).data[0].x;
      const centerY = chart.getDatasetMeta(0).data[0].y;

      ctx.save();
      ctx.font = "bold 45px sans-serif";
      ctx.fillStyle = "black";
      ctx.textAlign = "center";
      ctx.textBaseline = "middle";
      if (isCompliance) {
        ctx.fillText(`${percentage}%`, centerX, centerY);
      } else {
        ctx.fillText(`${nume}/${deno}`, centerX, centerY);
      }
    },
  };
  return <Doughnut data={data} options={options} plugins={[doughnutLabel]} />;
};
