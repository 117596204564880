import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faHome,
  faUser,
  faCog,
  faChartBar,
  faDesktop,
  faUserAstronaut,
  faList
} from "@fortawesome/free-solid-svg-icons";
import { IoLogOut } from "react-icons/io5";
import { useAuth } from "../context/useAuth";
import { Link, useLocation } from "react-router-dom";
import { useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import { FaChevronRight } from "react-icons/fa6";
import { CiMenuFries } from "react-icons/ci";
// import { FaEdit } from "react-icons/fa";

const Sidebar = () => {
  const location = useLocation();
  const options = [
    { name: "Dashboard", icon: faHome, url: "/dashboard" },
    { name: "Calendar", icon: faUser, url: "/calendar" },
    { name: "Clients", icon: faUserAstronaut, url: "/client" },
    {
      name: "Shiloh Alignment",
      icon: faUserAstronaut,
      url: "/shilohAlignment",
    },
    { name: "Program", icon: faList, url: "/program" },
    { name: "Appointment", icon: faCog, url: "/appointment" },
    { name: "Reports", icon: faChartBar, url: "/reports" },
  ];

  const optionForSuperAdmin = [
    { name: "Dashboard", icon: faHome, url: "/dashboard" },
    { name: "Calendar", icon: faUser, url: "/calendar" },
    { name: "Clients", icon: faUserAstronaut, url: "/client" },
    {
      name: "Shiloh Alignment",
      icon: faUserAstronaut,
      url: "/shilohAlignment",
    },
    { name: "Program", icon: faList, url: "/program" },
    { name: "Appointment", icon: faCog, url: "/appointment" },
    { name: "Departments", icon: faDesktop, url: "/department" },
    { name: "Sub Departments", icon: faDesktop, url: "/subdepartment" },
    { name: "Reports", icon: faChartBar, url: "/reports" },
    { name: "Users", icon: faUser, url: "/user" },
    
  ];

  const optionForViewer = [
    { name: "Dashboard", icon: faHome, url: "/dashboard" },
    { name: "Calendar", icon: faUser, url: "/calendar" },
    { name: "Program", icon: faList, url: "/program" }
  ];

  const { logout, role } = useAuth();
  const handleLogout = () => {
    logout();
  };

  const [show, setShow] = useState(false);

  const handleToggle = () => setShow(!show);
  const handleClose = () => setShow(false);

  return (
    <>
      <div className="">
        <div
          className="position-fixed "
          style={{ height: "100vh", width: "50px" }}
        >
          <button
            className="btn text-bg-dark"
            onClick={handleToggle}
            type="button"
            data-bs-toggle="offcanvas"
            data-disableScrolling="false"
            data-bs-target="#offcanvasExample"
            aria-controls="offcanvasExample"
            style={{
              height: "calc(100vh)",
            }}
          >
            <CiMenuFries />
            Menu
          </button>
        </div>

        <div
          className={`offcanvas offcanvas-start text-bg-dark`}
          tabIndex="-1"
          id="offcanvasExample"
          aria-labelledby="offcanvasExampleLabel"
        >
          <div className="offcanvas-header">
            <div className="d-flex align-items-center mb-3 mb-md-0 me-md-auto text-white text-decoration-none">
              <a href="/dashboard">
                <img
                  width="150"
                  height="80"
                  src="https://hypecounselingservices.org/wp-content/uploads/2020/06/HYPE_CARF-Logo-REAL-BIG-GOLD.png"
                  alt="logo"
                  style={{ marginLeft: "15px" }}
                />
              </a>
            </div>
            <button
              type="button"
              onClick={handleClose}
              className="btn-close text-reset "
              data-bs-dismiss="offcanvas"
              aria-label="Close"
              data-disableScrolling="false"
              style={{ filter: "invert(1)" }}
            ></button>
          </div>
          <div className="offcanvas-body">
            <hr />
            {role == "Super Admin" && (
              <ul className="nav nav-pills flex-column mb-auto">
                {optionForSuperAdmin.map((option, index) => (
                  <li
                    key={index}
                    className={
                      location.pathname === option.url
                        ? "active bg-primary rounded"
                        : ""
                    }
                  >
                    <a
                      href={option.url}
                      className="nav-link text-white d-flex  align-items-center"
                    >
                      <FontAwesomeIcon
                        className="bi pe-none me-2"
                        icon={option.icon}
                        width="16"
                        height="16"
                      />
                      <div>{option.name}</div>
                    </a>
                  </li>
                ))}
              </ul>
            )}
            {role == "Admin" && (
              <ul className="nav nav-pills flex-column mb-auto">
                {options.map((option, index) => (
                  <li
                    key={index}
                    className={
                      location.pathname === option.url
                        ? "active bg-primary rounded"
                        : ""
                    }
                  >
                    <a
                      href={option.url}
                      className="nav-link text-white d-flex  align-items-center"
                    >
                      <FontAwesomeIcon
                        className="bi pe-none me-2"
                        icon={option.icon}
                        width="16"
                        height="16"
                      />
                      <div>{option.name}</div>
                    </a>
                  </li>
                ))}
              </ul>
            )}

            {role == "Viewer" && (
              <ul className="nav nav-pills flex-column mb-auto">
                {optionForViewer.map((option, index) => (
                  <li
                    key={index}
                    className={
                      location.pathname === option.url
                        ? "active bg-primary rounded"
                        : ""
                    }
                  >
                    <a
                      href={option.url}
                      className="nav-link text-white d-flex  align-items-center"
                    >
                      <FontAwesomeIcon
                        className="bi pe-none me-2"
                        icon={option.icon}
                        width="16"
                        height="16"
                      />
                      <div>{option.name}</div>
                    </a>
                  </li>
                ))}
              </ul>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default Sidebar;
